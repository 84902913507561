<script>
import Vue from "vue";
import BaseVue from "@frontend/Base.vue";
import FOGen from "../helper/FOGen";
import Gen from "../helper/Gen";
import { MapPinIcon, MailIcon, PhoneIcon, AtSignIcon, SmartphoneIcon } from "vue-feather-icons";
import VForm from "../forms/VForm.vue";
import swal from "sweetalert";

export default {
  extends: BaseVue,
  data() {
    return {
      googleMap: "",
      email: "",
      address: "",
      phone: "",
      telephone: "",
      input: {
        ac_full_name: "",
        ac_email: "",
        ac_phone: "",
        ac_subject: "",
        ac_message: ""
      },
      heroImage: "",
      isLoading: false,
      disabled: false,
    };
  },
  components: {
    MapPinIcon,
    MailIcon,
    AtSignIcon,
    PhoneIcon,
    SmartphoneIcon,
    VForm,
  },
  computed: {
    mrValidation() {
      return this.$root.config.mrValidation.contact;
    },
  },
  async mounted() {
    this.getContactContet();
    await this.ready();
  },
  methods: {
    submitContact(e) {
      this.$refs["VForm"].validate().then((success) => {
        if (!success) {
          alert("ho");
          return;
        }
        this.disabled = true;
        this.isLoading = true;
        FOGen.apirest(
          "/submit-contact",
          this.input,
          (err, resp) => {
            if (err) {
              this.disabled = false;
              this.isLoading = false;
              if (err.status == 422) {
                Object.keys(err.responseJSON).forEach((k) => {
                  var textError = err.responseJSON[k][0];
                  if (textError == "validation.required")
                    textError = "Input Required";
                  $("[error=" + k + "]").html(
                    "<label class='text-danger'>" + textError + "</label>"
                  );
                });
              }
              if (err.status == 500) {
                Gen.info("Ups ada kesalahan terjadi", "danger", 3000, ".info");
              } else {
                swal({
                  title: err.responseJSON.message.title,
                  text: err.responseJSON.message.message,
                  icon: "warning",
                });
              }
            }
            this.input = {
              ac_full_name: "",
              ac_email: "",
              ac_phone: "",
              ac_subject: "",
              ac_message: ""
            };
            this.$nextTick(() => {
              this.$refs["VForm"].reset();
            });
            Gen.info(resp.message, resp.style, 3000, ".info");
            this.disabled = false;
            this.isLoading = false;
          },
          "POST"
        );
      });
    },
    getContactContet() {
      Gen.apirest("/contact", {}, (err, resp) => {
        if (err) console.log("Contact error : " + err);
        this.googleMap = resp.data.googleMap;
        this.telephone = resp.data.telephone;
        this.phone = resp.data.phone;
        this.address = resp.data.address;
        this.email = resp.data.email;
        this.heroImage = resp.data.heroImage;
      });
    },
  },
};
</script>
<template>
  <div>
    <section
      v-if="!this.$root.isMobile"
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img)}')`"
    >
      <div class="container">
      <span itemprop="text" class="d-none position-absolute">{{ heroImage.alt_img }}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Hubungi Kami</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active" aria-current="page"
                >
                  Hubungi Kami
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img_mobile)}')`"
    >
      <div class="container">
      <span itemprop="text" class="d-none position-absolute">{{ heroImage.alt_img_mobile }}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Hubungi Kami</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active" aria-current="page"
                >
                  Hubungi Kami
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      <div class="content-wrap">
        <section class="section py-64 py-contact">
          <div itemscope itemtype="https://schema.org/ContactPage" class="container">
            <div class="contact-head">
              <h2 itemprop="alternativeHeadline" class="contact__title">Kirimkan Pesan</h2>
              <span itemprop="description" class="contact__subtitle"
                >Kami akan senang menerima pesan dari Anda</span
              >
              <div class="info"></div>
            </div>
            <div class="contact-body">
              <div class="row">
                <div class="col-md-6">
                  <validation-observer v-slot="{ handleSubmit }" ref="VForm">
                    <form @submit.prevent="handleSubmit(submitContact)">
                      <div class="row gutter-16 col-mb-0">
                        <div class="col-12">
                          <validation-provider
                            #default="{ errors }"
                            name="ac_full_name"
                            rules="required|max:100|min:3|alpha_spaces"
                          >
                            <div class="form-group">
                              <label itemprop="name" for="nama">Nama</label>
                              <input
                                type="text"
                                placeholder="e.g. Cryan Fajri"
                                id="nama"
                                class="form-control"
                                name="ac_full_name"
                                v-model="input.ac_full_name"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label itemprop="name" for="email">Email</label>
                            <validation-provider
                              #default="{ errors }"
                              name="ac_email"
                              rules="required|email"
                              id="email"
                            >
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><at-sign-icon></at-sign-icon
                                  ></span>
                                </div>
                                <input
                                  type="email"
                                  placeholder="e.g. cryan@mail.com"
                                  name="ac_email"
                                  id="email"
                                  class="form-control"
                                  v-model="input.ac_email"
                                />
                              </div>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label itemprop="name" for="no_telp">No. Telepon/Whatsapp</label>
                            <validation-provider
                              #default="{ errors }"
                              name="ac_phone"
                              rules="numeric"
                              id="no_telp"
                            >
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><phone-icon></phone-icon
                                  ></span>
                                </div>
                                <input
                                  type="tel"
                                  placeholder="e.g. 08xxxxx"
                                  id="no_telfon"
                                  class="form-control"
                                  name="ac_phone"
                                  maxlength="13"
                                  v-model="input.ac_phone"
                                />
                              </div>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label itemprop="name" for="subjek">Subjek</label>
                            <validation-provider
                              #default="{ errors }"
                              name="ac_subject"
                              rules="required|min:3"
                              id="subjek"
                            >
                              <input
                                type="text"
                                placeholder="e.g. Saya ingin tahu soal biaya SPP"
                                id="subjek"
                                class="form-control"
                                name="ac_subject"
                                v-model="input.ac_subject"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label itemprop="name" for="pesan">Isi Pesan</label>
                            <validation-provider
                              #default="{ errors }"
                              name="ac_message"
                              rules="required|min:3"
                              id="pesan"
                            >
                              <textarea
                                name="ac_message"
                                id="pesan"
                                rows="6"
                                placeholder="e.g. berapa biaya spp bulanan di SD ini ya?"
                                class="form-control"
                                v-model="input.ac_message"
                              ></textarea>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="col-12">
                          <button
                            v-if="!isLoading"
                            type="submit"
                            class="cta cta--primary"
                          >
                            Kirim Pesan
                          </button>
                          <button
                            v-else
                            class="cta cta--primary btn btn-loading"
                            type="button"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                              style="width: 2rem; height: 2rem;"
                            ></span>
                            <span class="sr-only">Loading...</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </validation-observer>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                  <iframe
                    itemprop="contentLocation"
                    :src="googleMap"
                    height="480"
                    class="contact-map"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                  <div class="row">
                    <div class="col-lg-8">
                      <div itemscope itemtype="https://schema.org/PostalAddress" class="contact-list-contacts">
                        <div class="contact-list-contacts__item">
                          <map-pin-icon></map-pin-icon>
                          <span itemprop="streetAddress">{{ address }}</span>
                        </div>
                        <div class="contact-list-contacts__item">
                          <mail-icon></mail-icon>
                          <a itemprop="email" :href="'mailto:' + email" target="_blank">{{ email }}</a>
                        </div>
                        <div class="contact-list-contacts__item">
                          <phone-icon></phone-icon>
                          <i data-feather="phone"></i
                          ><a itemprop="telephone" :href="'tel:' + telephone" target="_blank">{{ telephone }}</a>
                        </div>
                        <div class="contact-list-contacts__item">
                          <smartphone-icon></smartphone-icon>
                          <a itemprop="telephone" :href="'tel:+' + phone" target="_blank">{{ phone }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
